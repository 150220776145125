import { Link } from "gatsby";
import * as React from "react"
import Layout from "../components/Layout";


import '../styles/index.scss';

// markup
const IndexPage = () => {

  return (
    <Layout>
      
      <header id="header-container">
        <div class="container">
            <header>
              <h1>Bartłomiej Ryba</h1>
              <h3>Full Stack Software Engineer</h3>
            </header>
        </div>
      </header> 
      <nav>
          <Link to='/about'>About me</Link>
          <Link to='/work'>Work</Link>
          <Link to='/contact'>Contact</Link>
      </nav>
    </Layout>
  )

}

export default IndexPage
